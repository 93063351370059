import React, {useState} from 'react';
import GuestSignup from "./pages/GuestSignup/GuestSignup";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import {PrivateRoute} from "./components/routes/PrivateRoute";
import UserSignup from "./pages/UserSignup/UserSignup";
import LocationQR from "./pages/LocationQR/LocationQR";
import Login from "./pages/Login/Login";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import AdminLandingPage from "./pages/Login/AdminLandingPage";
import Login2 from "./pages/Login/Login2";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#9953D5"
    }
  },
  typography: {
    fontFamily: ['Acumin']
  }
});

const initialAuth = {
  user: {},
  authenticated: false,
  fetched: false
};
export const AuthContext = React.createContext(initialAuth);

function App() {
  const [auth, setAuth] = useState(initialAuth);
  const [locations, setLocations] = useState([]);

  return (
      <AuthContext.Provider value={{auth, setAuth, locations, setLocations}}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Router>
              <Switch>
                <Route path="/signup/:uriName">
                  <GuestSignup/>
                </Route>
                <Route path="/thankyou">
                  <h1>THANK YOU</h1>
                </Route>
                <Route path="/lp">
                  <AdminLandingPage/>
                </Route>
                <Route path="/login">
                  <Login2/>
                </Route>
                <Route path="/invitation/:token">
                  <UserSignup/>
                </Route>
                <PrivateRoute path="/qr/locations/:id">
                  <LocationQR/>
                </PrivateRoute>
                <PrivateRoute path="/">
                  <AdminPanel/>
                </PrivateRoute>
              </Switch>
            </Router>
          </div>
        </MuiThemeProvider>
      </AuthContext.Provider>
  );
}

export default App;
