import React, {useEffect, useState} from "react";
import {fetchLocationQR} from "../../api/api";
import {useParams} from "react-router-dom";

const LocationQR = props => {
  const [qr, setQr] = useState();
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      fetchLocationQR(id)
          .then(resp => {
            if (!resp.error) {
              setQr(resp.response);
            }
          });
    }
  }, [id]);

  const content = qr ? (
      <div style={{margin: "auto"}}>
        <div style={{paddingLeft: "160px"}}>
          <h1>{qr.name}</h1>
          <h2>{qr.url}</h2>
        </div>
        <img src={`data:image/png;base64, ${qr.qr}`}/>
      </div>
  ) : <div/>;

  return (
      <>
        {content}
      </>
  )
}

export default LocationQR;