import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
import {fetchAllUsers, sendInvitation} from "../../../api/api";
import Button from "@material-ui/core/Button";
import InvitationDialog from "../../../components/dialog/InvitationDialog";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

export default function Users() {
  const classes = useStyles();
  const [t] = useTranslation();
  const [dataset, setDataset] = useState({
    rows: []
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAllUsers()
        .then(resp => {
          if (!resp.error) {
            const body = resp.response;
            setDataset({
              rows: body,
            })
          }
        });
  }, []);

  const handleClose = async (confirmed, email) => {
    setOpen(false);
    console.log(confirmed, email);
    if (confirmed && email) {
      const response = await sendInvitation(email);
      console.log(response);
    }
  }

  return (
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("panel.users.firstName")}</TableCell>
                  <TableCell>{t("panel.users.lastName")}</TableCell>
                  <TableCell>{t("panel.users.email")}</TableCell>
                  <TableCell>{t("panel.users.role")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataset.rows.map((row) => (
                    <TableRow hover style={{textDecoration: "none"}} key={row.id} component={Link}
                              to={`/users/${row.id}`}>
                      <TableCell>{row.firstName}</TableCell>
                      <TableCell>{row.lastName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.role}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br/>
        <Button variant="contained" color="primary" component="span" onClick={() => setOpen(true)}>
          {t("panel.users.invite")}
        </Button>
        <InvitationDialog
            classes={{
              paper: classes.paper,
            }}
            id="location-select"
            keepMounted
            open={open}
            handleClose={handleClose}
        />
      </div>
  );
}