export const formatDate = stringDate => {
  if (!stringDate) {
    return "";
  }
  const date = new Date(stringDate);
  const time = formatTime(date);
  return `${date.toDateString()} ${time}`;
}

export const formatAddress = address => {
  let formatted = "";
  if (address.street) {
    formatted += address.street;
  }

  if (address.municipality) {
    formatted += ` ${address.municipality}`;
  }

  if (address.city) {
    formatted += `, ${address.city}`;
  }

  return formatted;
}

export const formatWorkHours = workHours => {
  if (!workHours || !workHours.start || !workHours.end) {
    return " - ";
  }
  return `${workHours.start.slice(0, -3)} - ${workHours.end.slice(0, -3)}`;
}

export const formatTime = aDate => {
  return ("0" + aDate.getHours()).slice(-2) + ":" + ("0" + aDate.getMinutes()).slice(-2);
}

export const dateFromTime = time => {
  return new Date(new Date().toDateString() + " " + time);
}