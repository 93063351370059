import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Field} from "formik";
import MuiPhoneNumber from 'material-ui-phone-number';

const CustomMuiPhoneNumber = ({name, required, onChange, value, onBlur}) => {
  const [t] = useTranslation();
  return (
      <MuiPhoneNumber
          fullWidth
          id={name}
          label={t("guest.signup.phoneNumber.label")}
          name={name}
          variant="outlined"
          autoComplete={name}
          margin="normal"
          required={required}
          defaultCountry={'ch'}
          onlyCountries={['ch']}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
      />
  )
}

const FormPhoneNumberField = ({name, required}) => {

  return (
      <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <div>
              <CustomMuiPhoneNumber
                  name={name}
                  required={required}
                  placeholder="Phone Number"
                  onChange={value => {
                    console.log(value)
                    setFieldValue(name, value)
                  }}
                  value={field.value}
                  onBlur={field.onBlur}
              />
              {meta.touched && meta.error && (
                  <div style={{color: "red", marginLeft: "14px", fontSize: "0.75rem"}}>{meta.error}</div>
              )}
            </div>
        )}
      </Field>
  )
};

export default FormPhoneNumberField;