import React, {useEffect, useState} from "react";
import SignUpForm from "../../components/forms/SignUpForm";
import {findLocation} from "../../api/api";
import {useParams} from "react-router-dom";
import NotFound from "../error/NotFound";

const GuestSignup = props => {
  const {uriName} = useParams();
  const [state, setState] = useState({
    loading: false,
    location: undefined,
    notFound: false
  });

  useEffect(() => {
    setState({
      ...state,
      loading: true
    });
    findLocation(uriName)
        .then(resp => {
          if (!resp.error) {
            setState({
              loading: false,
              notFound: false,
              location: resp.response
            });
          } else {
            setState({
              loading: false,
              notFound: true,
              location: null
            });
          }
        })
  }, []);

  if (state.location) {
    return <SignUpForm location={state.location} />
  } else if (state.loading) {
    return <h1>LOADING</h1>
  } else {
    return <NotFound />
  }
}

export default GuestSignup;