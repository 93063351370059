import React, {useState} from "react";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {dateFromTime} from "../../utils/utils";

const TimePicker = ({label, time, handleTimeChange, enabled = true}) => {
  const date = dateFromTime(time);

  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
            label={label}
            ampm={false}
            mask="__:__"
            value={date}
            onChange={date => handleTimeChange(date)}
            disabled={!enabled}
        />
      </MuiPickersUtilsProvider>
  );
}

export default TimePicker;