import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
import './fonts/Acumin-RPro.otf';

ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={null}>
          <App/>
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
