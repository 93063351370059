import React, {useEffect, useState} from "react";
import LocationStepper from "../../../components/LocationStepper/LocationStepper";
import {useParams} from "react-router-dom";
import {fetchLocationDetails} from "../../../api/api";

const UpdateLocation = props => {
  const {id} = useParams();
  const [location, setLocation] = useState();

  useEffect(() => {
    fetchLocationDetails(id)
        .then(response => {
          if (!response.error) {
            setLocation(response.response);
          }
        });
  }, []);

  if (!location) {
    return <h4>LOADING</h4>
  }

  const flattenedLocation = {
    name: location.name,
    address: location.address.street,
    city: location.address.city,
    municipality: location.address.municipality,
    plz: location.address.plz,
    email: location.contactInfo.email,
    phoneNumber: location.contactInfo.phoneNumber,
    website: location.contactInfo.website,
    imageUrl:location.imageUrl,
    workHours: mapSchedule(location.workSchedule)
  }

  return (
      <LocationStepper initialData={flattenedLocation} mode={'UPDATE'} id={id}/>
  );
};

const mapSchedule = workSchedule => {
  return {
    monday: mapDay(workSchedule.monday),
    tuesday: mapDay(workSchedule.tuesday),
    wednesday: mapDay(workSchedule.wednesday),
    thursday: mapDay(workSchedule.thursday),
    friday: mapDay(workSchedule.friday),
    saturday: mapDay(workSchedule.saturday),
    sunday: mapDay(workSchedule.sunday)
  }
}

const mapDay = day => {
  if (day) {
    return {
      working: true,
      start: day.start,
      end: day.end
    }
  }
  return {
    working: false,
    start: "08:00",
    end: "23:00"
  }
}

export default UpdateLocation;