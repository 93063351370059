import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";

const InvitationDialog = ({open, handleClose}) => {

  const [t] = useTranslation();
  const [email, setEmail] = useState('');

  return (
      <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{t("panel.users.inviteTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("panel.users.inviteHint")}
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={event => setEmail(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={() => handleClose(true, email)} color="primary" autoFocus>
            {t("common.sendEmail")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default InvitationDialog;