import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Link as RouteLink, Link} from "react-router-dom";
import {fetchCustomers, fetchVisits} from "../../../api/api";
import {formatDate} from "../../../utils/utils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

export default function Visits() {
  const classes = useStyles();
  const [t] = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataset, setDataset] = useState({
    rows: [],
    count: 0
  });

  useEffect(() => {
    fetchVisits(page, rowsPerPage)
        .then(resp => {
          console.log(resp);
          if (!resp.error) {
            const body = resp.response;
            setDataset({
              rows: body.content,
              count: body.totalElements
            })
          }
        });
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>{t("panel.visits.checkInTime")}</TableCell>
                <TableCell>{t("panel.visits.name")}</TableCell>
                <TableCell>{t("panel.visits.location")}</TableCell>
                <TableCell>{t("panel.visits.checkOutTime")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataset.rows.map((row) => (
                  <TableRow hover style={{textDecoration: "none"}} key={row.id} component={RouteLink} to={`/visits/${row.id}`}>
                    <TableCell>{formatDate(row.checkInTime)}</TableCell>
                    <TableCell>{row.guestName}</TableCell>
                    <TableCell>{row.locationName}</TableCell>
                    <TableCell>{formatDate(row.checkOutTime)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataset.count > 10 && (<TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataset.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />)}
      </Paper>
  );
}