import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import DaySelector from "./DaySelector/DaySelector";
import {Button, Container, Grid, Paper, Step, StepLabel, Stepper} from "@material-ui/core";
import {formatTime} from "../../utils/utils";
import UploadImage from "./UploadImage/UploadImage";
import LocationInfoForm from "./LocationInfoForm/LocationInfoForm";
import {createLocation, fetchUserLocations, updateLocation, uploadFile} from "../../api/api";
import {AuthContext} from "../../App";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
    height: "600px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    position: "absolute",
    bottom: "0px"
  }
}));

const LocationStepper = ({initialData, mode = 'CREATE', id}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [locationData, setLocationData] = useState(initialData);
  const [imageFiles, setImageFiles] = useState();
  const {setLocations} = useContext(AuthContext);

  const onWorkDayChange = event => {
    setLocationData({
      ...locationData,
      workHours: {
        ...locationData.workHours,
        [event.target.name]: {
          ...locationData.workHours[event.target.name],
          working: event.target.checked
        }
      }
    });
  };

  const onTimeChange = (day, date, startOrEnd) => {
    setLocationData({
      ...locationData,
      workHours: {
        ...locationData.workHours,
        [day]: {
          ...locationData.workHours[day],
          [startOrEnd]: formatTime(date)
        }
      }
    });
  };

  const onDetailsChange = event => {
    setLocationData({
      ...locationData,
      [event.target.name]: event.target.value
    });
  };

  const submit = async () => {

    let imageUrl;

    if (imageFiles && imageFiles[0]) {
      const resp = await uploadFile(imageFiles[0]);
      if (resp.error) {
        console.log('There was an error uploading the file.', resp.error);
      } else {
        imageUrl = resp.response.filePath;
      }
    }
    const location = {
      name: locationData.name,
      address: {
        street: locationData.address,
        city: locationData.city,
        plz: locationData.plz,
        municipality: locationData.municipality
      },
      workSchedule: onlyWorkingDays(locationData.workHours),
      contactInfo: {
        email: locationData.email,
        phoneNumber: locationData.phoneNumber,
        website: locationData.website
      },
      imageUrl: imageUrl
    };
    let resp;
    if (mode === 'CREATE') {
      resp = await createLocation(location);
    } else if (mode === 'UPDATE') {
      resp = await updateLocation(id, location);
    } else {
      throw Error("Unknown mode for location.");
    }
    console.log(resp);

    if (!resp.error) {
      history.push('/locations');
      fetchUserLocations()
          .then(resp => {
            if (!resp.error) {
              setLocations(resp.response);
            }
          });
    }
  }

  const locationDetails = {
    name: locationData.name,
    address: locationData.address,
    city: locationData.city,
    municipality: locationData.municipality,
    plz: locationData.plz,
    email: locationData.email,
    phoneNumber: locationData.phoneNumber,
    website: locationData.website
  }

  const steps = {
    0: {
      label: t("panel.locations.locationDetails"),
      content: (<LocationInfoForm locationDetails={locationDetails} onChange={onDetailsChange}/>)
    },
    1: {
      label: t("panel.locations.workSchedule"),
      content: (<DaySelector selected={locationData.workHours} onWorkDayChange={onWorkDayChange}
                             onTimeChange={onTimeChange}/>)
    },
    2: {
      label: t("panel.locations.uploadImage"),
      content: (<UploadImage
          files={imageFiles}
          onImageChange={files => setImageFiles(files)}
          initialImg={initialData.imageUrl}
      />)
    }
  }

  const handleNext = async () => {
    if (activeStep === Object.keys(steps).length - 1) {
      await submit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {
            Object.keys(steps).map(key =>
                (<Step key={key}>
                  <StepLabel>{steps[key].label}</StepLabel>
                </Step>)
            )
          }
        </Stepper>
        <Paper className={classes.content}>
          {steps[activeStep].content}
        </Paper>
        <div>
          <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
          >
            {t("common.back")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {activeStep === Object.keys(steps).length - 1 ? t("common.submit") : t("common.next")}
          </Button>
        </div>
      </div>
  )
};

const onlyWorkingDays = workHours => {
  return {
    monday: workHours.monday.working ? workHours.monday : null,
    tuesday: workHours.tuesday.working ? workHours.tuesday : null,
    wednesday: workHours.wednesday.working ? workHours.wednesday : null,
    thursday: workHours.thursday.working ? workHours.thursday : null,
    friday: workHours.friday.working ? workHours.friday : null,
    saturday: workHours.saturday.working ? workHours.saturday : null,
    sunday: workHours.sunday.working ? workHours.sunday : null
  };
}

export default LocationStepper;