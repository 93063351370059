export const login = async (username, password) => {
  return postRequest('/api/accounts/login', {username, password})
}

export const logout = async () => {
  await fetch('/api/accounts/logout');
}

export const signUpGuest = async data => {
  return postRequest('/api/guests/signup', data);
};

export const fetchUserInfo = async () => {
  return await getRequest('/api/userinfo');
}

export const fetchLatestVisits = async () => {
  return await getRequest('/api/visits/today');
}

export const fetchNewCustomers = async () => {
  return await getRequest('api/guests/today')
}

export const fetchCustomers = async (page, size, guestCode) => {

  let guestCodeParam = "";
  if (guestCode) {
    guestCodeParam = `&guestCode=${guestCode}`;
  }
  return await getRequest(`/api/guests?page=${page}&size=${size}${guestCodeParam}`);
}

export const fetchCustomerDetails = async (id) => {
  return await getRequest(`/api/guests/${id}`);
}

export const fetchCustomersActiveVisit = async (id) => {
  return await getRequest(`/api/guests/${id}/visits/active`);
}

export const sendEmailToCustomer = async (guestId) => {
  return await postRequest(`/api/guests/${guestId}/email`, {});
}

export const fetchVisits = async (page, size) => {
  return await getRequest(`/api/visits?page=${page}&size=${size}`);
}

export const fetchVisitDetails = async (id) => {
  return await getRequest(`/api/visits/${id}`);
}

export const fetchLocations = async () => {
  return await getRequest('/api/locations');
}

export const checkInCustomer = async (guestCode, locationId) => {
  return await postRequest(`/api/checkin`, { guestCode, locationId});
}

export const checkOutCustomer = async (guestCode, locationId) => {
  return await postRequest(`/api/checkout`, { guestCode, locationId});
}

export const fetchAllUsers = async () => {
  return await getRequest("/api/accounts");
}

export const sendInvitation = async (email) => {
  return await postRequest('/api/accounts/invite', {email});
}

export const fetchInvitation = async (token) => {
  return await getRequest(`/api/accounts/invitation/${token}`);
}

export const createAccount = async (acc) => {
  return await postRequest('/api/accounts/new', acc);
}

export const fetchUserLocations = async () => {
  return await getRequest('/api/locations');
}

export const findLocation = async (uriName) => {
  return await getRequest(`/api/locations/search?uriName=${uriName}`);
}

export const createLocation = async (location) => {
  return await postRequest('/api/locations', location);
}

export const updateLocation = async (id, location) => {
  return await postRequest(`/api/locations/${id}`, location);
}

export const fetchLocationDetails = async (id) => {
  return await getRequest(`/api/locations/${id}`);
}

export const fetchLocationQR = async (id) => {
  return await getRequest(`/api/locations/${id}/qr`);
}

export const uploadFile = async file => {
  const formData = new FormData();
  formData.append("file", file);
  const resp = await fetch('/api/resources/upload', {
    method: 'POST',
    body: formData
  });

  const body = await resp.json();

  return {
    status: resp.status,
    response: body,
    error: resp.status >= 400
  }
}

export const importGuests = async (file, locationId) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("locationId", locationId);
  const resp = await fetch('/api/admin/import', {
    method: 'POST',
    body: formData
  });

  const body = await resp.json();

  return {
    status: resp.status,
    response: body,
    error: resp.status >= 400
  }
}

async function getRequest(url) {
  const response = await fetch(url);
  if (response.status === 401) {
    return {
      status: response.status,
      error: true
    }
  }
  const body = await response.json();
  return {
    status: response.status,
    response: body,
    error: response.status >= 400
  }
}

const postRequest = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });

  if (response.status === 401) {
    return {
      status: response.status,
      error: true
    }
  }

  const jsonBody = await response.json();
  return {
    status: response.status,
    response: jsonBody,
    error: response.status >= 400
  }
}