import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {fetchLatestVisits} from "../../../api/api";
import {Link, Link as RouteLink} from "react-router-dom";
import {formatDate} from "../../../utils/utils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function LatestVisits() {
  const [visits, setVisits] = useState([]);
  const [t] = useTranslation();
  useEffect(() => {
    fetchLatestVisits()
        .then(response => setVisits(response.response));
  }, []);

  const classes = useStyles();
  return (
      <React.Fragment>
        <Title>{t("panel.dashboard.visitsHeader")}</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("panel.visits.checkInTime")}</TableCell>
              <TableCell>{t("panel.visits.name")}</TableCell>
              <TableCell>{t("panel.visits.location")}</TableCell>
              <TableCell>{t("panel.visits.checkOutTime")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visits.map((visit) => (
                <TableRow hover style={{textDecoration: "none"}} key={visit.id} component={RouteLink} to={`/visits/${visit.id}`}>
                  <TableCell>{formatDate(visit.checkInTime)}</TableCell>
                  <TableCell>{visit.guestName}</TableCell>
                  <TableCell>{visit.locationName}</TableCell>
                  <TableCell>{formatDate(visit.checkOutTime)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.seeMore}>
          <RouteLink color="primary" to="/visits">
            {t("panel.dashboard.moreVisits")}
          </RouteLink>
        </div>
      </React.Fragment>
  );
}
