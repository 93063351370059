import React, {useContext, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, Redirect, useHistory} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, FormControl, Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import whiteLogo from '../../images/chin-Logo-white.png';
import TextField from "@material-ui/core/TextField";
import {login} from "../../api/api";
import {AuthContext} from "../../App";
import FormHelperText from "@material-ui/core/FormHelperText";

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ch-in.ch/">
          CHIN
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#9953D5',
    padding: theme.spacing(3)
  },
  input: {
    backgroundColor: 'white',
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  image: {
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#C193E6",
    color: "white"
  },
}));

const Login2 = () => {
  const classes = useStyles();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const {auth, setAuth} = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const history = useHistory();

  const onSubmit = async (event) => {
    event.preventDefault();
    const resp = await login(username, password);
    if (!resp.error) {
      setAuth({
        user: resp.response,
        authenticated: true,
        fetched: true
      });
    } else {
      setError(true);
      setErrorText("Falscher Email oder Passwort.");
    }
  }

  if (auth.authenticated) {
    return <Redirect
        to={{
          pathname: "/"
        }}
    />
  }

  return (

      <form noValidate onSubmit={onSubmit}>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={3} className={classes.paper}>
          <Grid item xs={12} className={classes.image}>
            <img src={whiteLogo} alt="" width={150} align="right"/>
          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField

                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Adresse"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={event => setUsername(event.target.value)}

            />

          </Grid>
          <Grid item xs={12} className={classes.input}>
            <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
            />
          </Grid>
          <FormHelperText>{errorText}</FormHelperText>
          <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
          >
            Login
          </Button>
        </Grid>
        <Box mt={8}>
          <Copyright/>
        </Box>
      </Container>
      </form>
  )
};

export default Login2;