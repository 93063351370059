import React from "react";
import {TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const TranslatedTextField = ({name, namespace, value, onChange, ...rest}) => {

  const [t] = useTranslation();

  return (
      <TextField
          name={name}
          id={name}
          label={t(translationLabelKey(namespace, name))}
          autoComplete={name}
          margin="normal"
          fullWidth
          variant="outlined"
          value={value}
          onChange={onChange}
          {...rest}
      />
  )
};

const translationLabelKey = (namespace, name) => {
  return `${namespace}.${name}.label`;
}

export default TranslatedTextField;