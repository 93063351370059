import Typography from "@material-ui/core/Typography";
import React from "react";
import logo from '../../images/chin-icon.jpg';
import {Grid} from "@material-ui/core";

export const FormHeader = ({location}) => {
  const img = location.imageUrl ? location.imageUrl : logo;
  return <Grid container spacing={1}>
    <Grid item container xs={12}>
      <Grid item container xs={10}>
        <Grid item xs={12} alignItems="flex-end">
          <Typography
              component="h2"
              variant="h2"
              color="inherit"
              align="left"
          >
            <strong>ch</strong>in
          </Typography>
          <Typography
              component="h2"
              variant="h2"
              color="inherit"
              align="left"
          >
            QR Check-In System
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={2} align="left" spacing={0}>
        <Grid item xs={12}>
          <img src={img} width="81" height="81"/>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            <strong>{location.name}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            {location.address.street}
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            {`${location.address.plz} ${location.address.municipality} ${location.address.city}`}
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            {location.contactInfo.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            {location.contactInfo.email}
          </Typography>
        </Grid>
        <Grid item xs={12} align="left">
          <Typography variant="caption" display="block">
            {location.contactInfo.website}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
      ;
};