import React from "react";
import {TextField} from "formik-material-ui";
import {Field} from "formik";
import {useTranslation} from "react-i18next";

const FormTextField = ({name, namespace, required, ...rest}) => {

  const [t] = useTranslation();
  return (
      <Field
          component={TextField}
          fullWidth
          id={name}
          label={t(translationLabelKey(namespace, name))}
          name={name}
          variant="outlined"
          autoComplete={name}
          margin="normal"
          required={required}
          {...rest}
      />
  )
};

const translationLabelKey = (namespace, name) => {
  return `${namespace}.${name}.label`;
}

export default FormTextField;