import React, {useContext, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import {fetchNewCustomers} from "../../../api/api";
import {Link} from 'react-router-dom';
import {AuthContext} from "../../../App";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function NewCustomers() {
  const {locations} = useContext(AuthContext);
  const [customers, setCustomers] = useState([]);
  const [t] = useTranslation();
  useEffect(() => {
    fetchNewCustomers()
        .then(response => setCustomers(response.response));
  }, []);

  const classes = useStyles();

  const locationName = id => {
    const location = locations.find(l => l.id === id);
    return location ? location.name : "";
  }

  return (
      <React.Fragment>
        <Title>{t("panel.dashboard.registrationsHeader")}</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t("panel.customers.id")}</TableCell>
              <TableCell>{t("panel.customers.firstName")}</TableCell>
              <TableCell>{t("panel.customers.lastName")}</TableCell>
              <TableCell>{t("panel.customers.email")}</TableCell>
              <TableCell>{t("panel.customers.phoneNumber")}</TableCell>
              <TableCell>{t("panel.customers.location")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map((c) => (
                <TableRow hover style={{textDecoration: "none"}} key={c.id} component={Link} to={`/customers/${c.id}`}>
                  <TableCell>{c.code}</TableCell>
                  <TableCell>{c.firstName}</TableCell>
                  <TableCell>{c.lastName}</TableCell>
                  <TableCell>{c.email}</TableCell>
                  <TableCell>{c.phoneNumber}</TableCell>
                  <TableCell>{locationName(c.signupLocationId)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.seeMore}>
          <Link color="primary" to="/customers">
            {t("panel.dashboard.moreCustomers")}
          </Link>
        </div>
      </React.Fragment>
  );
}
