import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DropzoneArea} from "material-ui-dropzone";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  dropzone: {
    width: '40%',
    height: '40%',
    margin: "0 auto",
    top: "25%"
  }
});

const UploadImage = ({files, onImageChange, initialImg}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const initialFiles = initialImg ? [initialImg] : undefined

  return (
      <DropzoneArea
          dropzoneClass={classes.dropzone}
          filesLimit={1}
          acceptedFiles={['image/png', 'image/x-icon', 'image/jpeg', 'image/svg+xml']}
          onChange={onImageChange}
          maxWidth="xs"
          dropzoneText={t("location.upload.label")}
          initialFiles={initialFiles}
      />
  )
}

export default UploadImage;