import React, {useContext} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import CloudUpload from '@material-ui/icons/CloudUpload';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {Link} from 'react-router-dom';
import {AuthContext} from "../../../App";
import {ROLE_SUPER_ADMIN} from "../../../constants";
import {useTranslation} from "react-i18next";

const ListItems = ({selected}) => {

  const {auth} = useContext(AuthContext);
  const [t] = useTranslation();

  return (<div>
    <ListItem button component={Link} to="/dashboard" selected={selected === 'dashboard'}>
      <ListItemIcon>
        <DashboardIcon color="#ffffff"/>
      </ListItemIcon>
      <ListItemText primary={t("panel.list.dashboard")}/>
    </ListItem>
    <ListItem button component={Link} to="/customers" selected={selected === 'customers'}>
      <ListItemIcon>
        <PeopleIcon/>
      </ListItemIcon>
      <ListItemText primary={t("panel.list.customers")}/>
    </ListItem>
    <ListItem button component={Link} to="/visits" selected={selected === 'visits'}>
      <ListItemIcon>
        <LayersIcon/>
      </ListItemIcon>
      <ListItemText primary={t("panel.list.visits")}/>
    </ListItem>
    <ListItem button component={Link} to="/locations" selected={selected === 'locations'}>
      <ListItemIcon>
        <LocationCityIcon/>
      </ListItemIcon>
      <ListItemText primary={t("panel.list.locations")}/>
    </ListItem>
    { auth.user.role === ROLE_SUPER_ADMIN &&
      <ListItem button component={Link} to="/users" selected={selected === 'users'}>
        <ListItemIcon>
          <PeopleIcon/>
        </ListItemIcon>
        <ListItemText primary={t("panel.list.users")}/>
      </ListItem>
    }
    { auth.user.role === ROLE_SUPER_ADMIN &&
      <ListItem button component={Link} to="/import" selected={selected === 'import'}>
        <ListItemIcon>
          <CloudUpload/>
        </ListItemIcon>
        <ListItemText primary={t("panel.list.import")}/>
      </ListItem>
    }
    <ListItem button component={Link} to="/reports" selected={selected === 'reports'}>
      <ListItemIcon>
        <BarChartIcon/>
      </ListItemIcon>
      <ListItemText primary={t("panel.list.reports")}/>
    </ListItem>
  </div>);
}
export default ListItems;