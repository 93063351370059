import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {fetchLocations} from "../../api/api";
import {useTranslation} from "react-i18next";

const LocationDialog = (props) => {
  const [t] = useTranslation();
  const [value, setValue] = useState('');
  const { onClose, open, ...other } = props;
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    fetchLocations()
        .then(resp => {
          if (!resp.error) {
            setLocations(resp.response);
            if (resp.response.length > 0) {
              setValue(`${resp.response[0].id}`);
            }
          }
        });
  }, [])

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
      <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={open}
          {...other}
      >
        <DialogTitle id="confirmation-dialog-title">{t("panel.customers.chooseLocation")}</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
              aria-label="location"
              name="location"
              defaultValue={value}
              value={value}
              onChange={event => setValue(event.target.value)}
          >
            {locations.map((l) => (
                <FormControlLabel value={`${l.id}`} key={l.id} control={<Radio />} label={l.name} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleOk} color="primary">
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default LocationDialog;