import React, {useContext, useEffect, useState} from "react";
import {fetchUserInfo, fetchUserLocations} from "../../api/api";
import {Redirect, Route} from "react-router-dom";
import {AuthContext} from "../../App";
import {ROLE_SUPER_ADMIN} from "../../constants";

export function SuperAdminRoute({children, ...rest}) {
  const {auth, setAuth, setLocations} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!auth.fetched) {
      fetchUserInfo()
          .then(response => {
            if (response.status === 401) {
              setLoading(false);
              setAuth({
                user: {},
                authenticated: false,
                fetched: true
              });
            } else {
              setAuth({
                user: response.response,
                authenticated: true,
                fetched: true
              });
            }
          });
    }
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      fetchUserLocations()
          .then(resp => {
            if (!resp.error) {
              setLocations(resp.response);
            }
          })
    }
  }, [auth.authenticated]);

  return (
      <Route
          {...rest}
          render={({location}) =>
              auth.authenticated && auth.user.role === ROLE_SUPER_ADMIN ? (
                  children
              ) : (
                  loading ? <div>LOADING</div> :
                      <Redirect
                          to={{
                            pathname: "/",
                            state: {from: location}
                          }}
                      />
              )
          }
      />
  );
}