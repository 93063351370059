import React, {useState} from "react";
import {Card, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import TimePicker from "../../pickers/TimePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  },
  formControl: {
    margin: theme.spacing(10),
  },
  card: {
    padding: theme.spacing(2)
  }
}));

const DaySelector = ({selected, onWorkDayChange, onTimeChange}) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
      <Container className={classes.root}>
        <Grid container spacing="10">
          {Object.keys(selected).map(day => (
                  <Grid item xs="3">
                    <Grid container>
                      <Grid item xs="6">
                        <FormControlLabel
                            key={day}
                            control={<Checkbox key={day} color="primary" checked={selected[day].working} onChange={onWorkDayChange} name={day}/>}
                            label={t(`common.${day}`)}
                        />
                      </Grid>
                      <Grid item>
                        <Card className={classes.card}>
                          <TimePicker
                              label="Start"
                              time={selected[day].start}
                              handleTimeChange={date => onTimeChange(day, date, "start")}
                              enabled={selected[day].working}
                          />
                          <TimePicker
                              label="End"
                              time={selected[day].end}
                              handleTimeChange={date => onTimeChange(day, date, "end")}
                              enabled={selected[day].working}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                </Grid>
          ))}
        </Grid>
      </Container>
  )
}

export default DaySelector;