import React from "react";
import LocationStepper from "../../../components/LocationStepper/LocationStepper";

const initialData = {
  name: "",
  address: "",
  city: "",
  municipality: "",
  plz: "",
  email: "",
  phoneNumber: "",
  website: "",
  imageUrl: "",
  workHours: {
    monday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    tuesday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    wednesday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    thursday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    friday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    saturday: {
      working: true,
      start: "08:00",
      end: "23:00"
    },
    sunday: {
      working: false,
      start: "08:00",
      end: "23:00"
    }
  }
}

const NewLocation = props => {
  return (
      <LocationStepper initialData={initialData}/>
  )
}

export default NewLocation;