import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {DropzoneArea} from "material-ui-dropzone";
import {makeStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";
import {importGuests} from "../../../api/api";
import LocationDialog from "../../../components/dialog/LocationDialog";

const useStyles = makeStyles({
  dropzone: {
    width: '40%',
    height: '40%',
    top: "25%"
  }
});

const GuestImport = props => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState('IDLE');
  const [result, setResult] = useState();
  const [open, setOpen] = useState(false);
  const onChange = files => {
    setFiles(files);
  }

  const submit = async locationId => {
    setStatus('UPLOADING');
    const resp = await importGuests(files[0], locationId);
    if (!resp.error) {
      setStatus('FINISHED');
      setResult(resp.response);
    } else {
      setStatus('FAILED');
    }
  }
  const handleLocationClose = async (locationId) => {
    setOpen(false);
    if (locationId) {
      await submit(locationId);
    }
  };

  let content;
  switch (status) {
    case 'IDLE':
      const submitDisabled = files.length === 0;
      content = (
          <div>
            <DropzoneArea
                dropzoneClass={classes.dropzone}
                filesLimit={1}
                acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
                onChange={onChange}
                maxWidth="xs"
                dropzoneText="Upload CSV"
            />
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                disabled={submitDisabled}
            >
              {t("common.submit")}
            </Button>
            <LocationDialog
                classes={{
                  paper: classes.paper,
                }}
                id="location-select"
                keepMounted
                open={open}
                onClose={handleLocationClose}
            />
          </div>
      );
      break;
    case 'UPLOADING':
      content = (
          <div>LOADING...</div>
      );
      break;
    case 'FINISHED':
      content = (
          <div>
            <h3>Upload completed</h3>
            {result ? (<h4>SUCCESS: {result.successCount}, FAIL: {result.failCount}</h4>) : <div />}
          </div>
      );
      break;
  }

  return content;
};

export default GuestImport;