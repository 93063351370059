import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  checkInCustomer,
  checkOutCustomer,
  fetchCustomerDetails,
  fetchCustomersActiveVisit,
  sendEmailToCustomer
} from "../../../api/api";
import {useParams} from 'react-router-dom';
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LocationDialog from "../../../components/dialog/LocationDialog";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import {useTranslation} from "react-i18next";
import MailIcon from '@material-ui/icons/Mail';
import SendEmailDialog from "../../../components/dialog/SendEmailDialog";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function CustomerDetails() {
  const classes = useStyles();
  const [t] = useTranslation();
  const [customer, setCustomer] = useState({});
  const [visit, setVisit] = useState();
  const [open, setOpen] = useState(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState(false);
  const {id} = useParams('id');
  useEffect(() => {
    fetchCustomerDetails(id)
        .then(response => {
          if (!response.error) {
            setCustomer(response.response);
          }
        });
  }, []);
  useEffect(() => {
    fetchCustomersActiveVisit(id)
        .then(response => {
          if (!response.error) {
            setVisit(response.response);
          }
        });
  }, []);

  const checkIn = async (locationId) => {
    const response = await checkInCustomer(customer.code, locationId);
    if (!response.error) {
      setVisit(response.response);
    }
  };

  const checkOut = async () => {
    const response = await checkOutCustomer(customer.code, visit.locationId);
    if (!response.error) {
      setVisit(null);
    }
  };

  const handleClickCheckin = () => {
    setOpen(true);
  };

  const handleCheckInClose = async (locationId) => {
    setOpen(false);
    if (locationId) {
      await checkIn(locationId);
    }
  };

  const handleCheckOutClose = async (agreed) => {
    setOpen(false);
    if (agreed) {
      await checkOut();
    }
  };

  const handleSendEmailClick = async () => {
    setEmailPopupOpen(true);
  }

  const handleSendEmailClose = async (agreed) => {
    setEmailPopupOpen(false);
    if (agreed) {
      await sendEmailToCustomer(customer.id);
    }
  }

  const info = visit ? (
      <div>
        <Typography variant="subtitle1">
          {t("panel.customers.checkedInAt")} <strong>{visit.locationName}</strong>.
        </Typography>
        <br/>
        <Button variant="contained" color="primary" component="span" onClick={() => setOpen(true)}>
          CHECK OUT ...
        </Button>
        <br />
        <br />
        <Button variant="contained" color="blue" component="span" onClick={handleSendEmailClick} endIcon={<MailIcon/>}>
          E-Mail senden
        </Button>
        <ConfirmDialog
            open={open}
            name={visit.guestName}
            handleClose={handleCheckOutClose}
            location={visit.locationName}
        />
      </div>

  ) : (
      <div>
        <Typography variant="subtitle1">
          {t("panel.customers.notCheckedInInfo")}
        </Typography>
        <br/>
        <Button variant="contained" color="primary" component="span" onClick={handleClickCheckin}>
          CHECK IN ...
        </Button>
        <br />
        <br />
        <Button variant="contained" color="blue" component="span" onClick={handleSendEmailClick} endIcon={<MailIcon/>}>
          E-Mail senden
        </Button>
        <LocationDialog
            classes={{
              paper: classes.paper,
            }}
            id="location-select"
            keepMounted
            open={open}
            onClose={handleCheckInClose}
        />
      </div>
  );

  return (
      <>
        <Typography variant="h2">
          {`${customer.firstName} ${customer.lastName}`}
        </Typography>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>{t("panel.customers.email")}</TableCell>
                <TableCell>{customer.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("panel.customers.id")}</TableCell>
                <TableCell>{customer.code}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("panel.customers.phoneNumber")}</TableCell>
                <TableCell>{customer.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("panel.customers.address")}</TableCell>
                <TableCell>{customer.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("panel.customers.plz")}</TableCell>
                <TableCell>{customer.plz}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br/>
        {info}
        <SendEmailDialog
            open={emailPopupOpen}
            email={customer.email}
            handleClose={handleSendEmailClose}
        />
      </>
  );
}
