import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import * as Yup from 'yup';
import {ErrorMessage, Field, Form, Formik} from "formik";
import FormTextField from "./FormTextField";
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {FormHeader} from "./FormHeader";
import {CheckboxWithLabel} from "formik-material-ui";
import FormPhoneNumberField from "./FormPhoneNumberField";
import {signUpGuest} from "../../api/api";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  page: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  header: {
    spacing: '5'
  }
}));

export default function SignUpForm({location}) {
  const [t] = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  console.log(location);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .required(t("guest.signup.firstName.validation.required")),
    lastName: Yup.string()
        .required(t("guest.signup.lastName.validation.required")),
    email: Yup.string()
        .email(t("guest.signup.email.validation.valid"))
        .required(t("guest.signup.email.validation.required")),
    phoneNumber: Yup.string()
        .required(t("guest.signup.phoneNumber.validation.required"))
        .matches(/(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/,
            t("guest.signup.phoneNumber.validation.valid")
        ),
    plz: Yup.string()
        .matches(/^[1-9]\d{3}$/, t("guest.signup.plz.validation.valid")),
    address: Yup.string(),
    agreement: Yup.bool()
        .required(t("guest.signup.agreement.validation.required"))
        .oneOf([true], t("guest.signup.agreement.validation.required"))
  });

  const showErrors = (errCode, setErrors) => {
    if (errCode.startsWith("ERR_EMAIL")) {
      setErrors({email: t(`guest.signup.email.validation.backend.${errCode}`)})
    } else if (errCode.startsWith("ERR_PHONE")) {
      setErrors({phoneNumber: t(`guest.signup.phoneNumber.validation.backend.${errCode}`)})
    }
  }

  const onSubmit = async (values, {setErrors}) => {
    console.log(values);
    const {response, error} = await signUpGuest({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      address: values.address,
      plz: values.plz,
      locationId: location.id
    });

    if (error) {
      showErrors(response.code, setErrors);
    } else {
      history.push('/thankyou');
    }
  }

  return (
      <Container component="main" maxWidth="md" className={classes.page}>
        <FormHeader location={location}/>
        <Grid container xs={10}>
          <div className={classes.header}>
            <Typography
                component="h3"
                variant="h3"
                color="inherit"
                align="left"
            >
              {t("guest.signup.header")}
            </Typography>
            <Typography
                variant="subtitle1"
                color="inherit"
                align="left"
                gutterBottom
            >
              {t("guest.signup.hint1")}
            </Typography>
            <Typography
                variant="subtitle2"
                color="inherit"
                align="left"
                gutterBottom
            >
              <strong>{t("guest.signup.hint2")}</strong>
            </Typography>
            <Typography
                variant="subtitle1"
                color="inherit"
                align="left"
                gutterBottom
            >
              {t("guest.signup.hint3")}
            </Typography>
          </div>
          <CssBaseline/>
          <div className={classes.paper}>
            <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  plz: '',
                  address: '',
                  agreement: false
                }}
                validationSchema={SignupSchema}
                onSubmit={onSubmit}
            >
              {({errors, touched}) => (
                  <Form className={classes.form} noValidate>
                    <FormTextField
                        name="firstName"
                        namespace="guest.signup"
                        required
                    />
                    <FormTextField
                        name="lastName"
                        namespace="guest.signup"
                        required
                    />
                    <FormTextField
                        name="address"
                        namespace="guest.signup"
                    />
                    <FormTextField
                        name="plz"
                        namespace="guest.signup"
                    />
                    <FormPhoneNumberField
                        name="phoneNumber"
                        required
                    />
                    <FormTextField
                        name="email"
                        namespace="guest.signup"
                        required
                    />
                    <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="agreement"
                        id="agreement"
                        Label={{label: t("guest.signup.agreement.label")}}
                    />
                    <a href="https://ch-in.ch/Datenbearbeitungen.html" target="_blank">Rechtliche Hinweise</a>
                    <div style={{color: "red", marginLeft: "14px", fontSize: "0.75rem"}}>
                      <ErrorMessage name="agreement"/>
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                      {t("guest.signup.submit")}
                    </Button>
                  </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Container>

  );
}