import React, {useContext, useEffect, useState} from "react";
import {fetchUserInfo, fetchUserLocations} from "../../api/api";
import {Redirect, Route} from "react-router-dom";
import {AuthContext} from "../../App";

export function PrivateRoute({children, ...rest}) {
  const {auth, setAuth, setLocations} = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!auth.fetched) {
      fetchUserInfo()
          .then(response => {
            if (response.status !== 200) {
              setLoading(false);
              setAuth({
                user: {},
                authenticated: false,
                fetched: true
              });
            } else {
              setAuth({
                user: response.response,
                authenticated: true,
                fetched: true
              });
            }
          });
    }
  }, [auth.fetched]);

  useEffect(() => {
    if (auth.authenticated) {
      fetchUserLocations()
          .then(resp => {
            if (!resp.error) {
              setLocations(resp.response);
            }
          })
    }
  }, [auth.authenticated]);

  return (
      <Route
          {...rest}
          render={({location}) =>
              auth.authenticated ? (
                  children
              ) : (
                  loading ? <div>LOADING</div> :
                      <Redirect
                          to={{
                            pathname: "/lp",
                            state: {from: location}
                          }}
                      />
              )
          }
      />
  );
}