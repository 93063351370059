import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useParams} from "react-router-dom";
import {checkOutCustomer, fetchCustomerDetails, fetchVisitDetails} from "../../../api/api";
import {makeStyles} from "@material-ui/core/styles";
import {formatDate, formatTime} from "../../../utils/utils";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../../components/dialog/ConfirmDialog";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const VisitDetails = props => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [visit, setVisit] = useState();
  const [open, setOpen] = useState(false);
  const [checkOutPerformed, setCheckOutPerformed] = useState(false);

  const {id} = useParams('id');
  useEffect(() => {
    fetchVisitDetails(id)
        .then(response => {
          if (!response.error) {
            setVisit(response.response);
          }
        });
  }, [checkOutPerformed]);

  const checkOut = async () => {
    const response = await checkOutCustomer(visit.guestCode, visit.locationId);
    if (!response.error) {
      setCheckOutPerformed(true);
    }
  };

  const handleCheckOutClose = async (agreed) => {
    setOpen(false);
    if (agreed) {
      await checkOut();
    }
  };

  const checkoutButton = visit && !visit.checkOutTime && <div>
    <Button variant="contained" color="primary" component="span" onClick={() => setOpen(true)}>
      CHECK OUT ...
    </Button>
    <ConfirmDialog
        open={open}
        name={visit.guestName}
        handleClose={handleCheckOutClose}
        location={visit.locationName}
    />
  </div>;

  const visitTable = visit && <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell>{t("panel.visits.guest")}</TableCell>
          <TableCell>{`${visit.guestName} (${visit.guestCode})`}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("panel.visits.location")}</TableCell>
          <TableCell>{visit.locationName}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("panel.visits.checkInTime")}</TableCell>
          <TableCell>{formatDate(visit.checkInTime)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t("panel.visits.checkOutTime")}</TableCell>
          <TableCell>{formatDate(visit.checkOutTime)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>;

  return (
      <>
        <Typography variant="h2">
          {t("panel.visits.visitDetails")}
        </Typography>
        {visitTable}
        <br/>

        {checkoutButton}
      </>
  );
};

export default VisitDetails;