import React, {useContext} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItems from "./Dashboard/ListItems";
import {makeStyles} from "@material-ui/core/styles";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Container from "@material-ui/core/Container";
import CustomerDetails from "./Customers/CustomerDetails";
import Customers from "./Customers/Customers";
import VisitDetails from "./Visits/VisitDetails";
import Visits from "./Visits/Visits";
import Users from "./Users/Users";
import {SuperAdminRoute} from "../../components/routes/SuperAdminRoute";
import UserDetails from "./Users/UserDetails";
import Locations from "./Locations/Locations";
import LocationDetails from "./Locations/LocationDetails";
import {Button} from "@material-ui/core";
import {AuthContext} from "../../App";
import {logout} from "../../api/api";
import {useTranslation} from "react-i18next";
import NewLocation from "./Locations/NewLocation";
import UpdateLocation from "./Locations/UpdateLocation";
import GuestImport from "./Import/GuestImport";
import whiteLogo from '../../images/chin-Logo-white.png';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logo: {
    position: 'absolute',
    bottom: 10,
    left: 10
  }
}));

const trimPath = path => {
  return path.slice(1).split("/")[0];
}

const AdminPanel = () => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(true);
  const {setAuth} = useContext(AuthContext);
  const location = useLocation();
  const currentPage = trimPath(location.pathname);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await logout();
    setAuth({
      user: {},
      authenticated: false,
      fetched: false
    });
  }
  return (
      <div className={classes.root}>
        <CssBaseline/>
        <AppBar color="#ffffff" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon/>
            </IconButton>
            <Typography component="h1" variant="h6" color="primary" noWrap className={classes.title}>
              {t(`panel.list.${currentPage}`)}
            </Typography>
            <IconButton color="primary">
              <Button color="primary" onClick={handleLogout}>Logout</Button>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            color="primary"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon/>
            </IconButton>
          </div>
          <Divider/>
          <List color="primary">
            <ListItems selected={currentPage}/>
          </List>
          <img src={whiteLogo} width={150} className={classes.logo}/>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path={`/dashboard`}>
                <Dashboard/>
              </Route>
              <Route exact path={'/customers'}>
                <Customers/>
              </Route>
              <Route exact path={'/customers/:id'}>
                <CustomerDetails/>
              </Route>
              <Route exact path={'/visits'}>
                <Visits/>
              </Route>
              <Route exact path={'/visits/:id'}>
                <VisitDetails/>
              </Route>
              <Route exact path={'/locations'}>
                <Locations/>
              </Route>
              <Route exact path={'/locations/new'}>
                <NewLocation/>
              </Route>
              <Route exact path={'/locations/:id'}>
                <LocationDetails/>
              </Route>
              <Route exact path={'/locations/:id/edit'}>
                <UpdateLocation/>
              </Route>
              <SuperAdminRoute exact path={'/users'}>
                <Users/>
              </SuperAdminRoute>
              <SuperAdminRoute exact path={'/users/:id'}>
                <UserDetails/>
              </SuperAdminRoute>
              <SuperAdminRoute exact path={'/import'}>
                <GuestImport/>
              </SuperAdminRoute>
              <Route exact path={`/`}>
                <Redirect
                    to={{
                      pathname: "/dashboard",
                      state: {from: location}
                    }}
                />
              </Route>
            </Switch>
          </Container>
        </main>
      </div>
  );
};

export default AdminPanel;