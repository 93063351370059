import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import whiteLogo from '../../images/chin-Logo-white.png';

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ch-in.ch/">
          CHIN
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#9953D5',
    padding: theme.spacing(3)
  },
  link: {
    backgroundColor: 'white',
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(3)
  },
  image: {
    width: '100%'
  }
}));

const AdminLandingPage = () => {
  const classes = useStyles();

  return (
      <Container component="main" maxWidth="sm">
        <Grid container spacing={3} className={classes.paper}>
          <Grid item xs={12} className={classes.image}>
            <img src={whiteLogo} alt="" width={150} align="right"/>
          </Grid>
          <Grid item xs={12} className={classes.link}>
            <RouterLink to="/login"  style={{textDecoration: "none"}}>
              <Typography variant="h4" align="center">
                Login Admin Panel
              </Typography>
            </RouterLink>
          </Grid>
          <Grid item xs={12} className={classes.link}>
            <a href="https://ch-in.ch/Datenbearbeitungen.html" target="_blank" style={{textDecoration: "none"}}>
              <Typography variant="h4" align="center">
                Datenschutzerklärung
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Copyright/>
        </Box>
      </Container>
  )
};

export default AdminLandingPage;