import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";

const ConfirmDialog = ({open, handleClose, name, location}) => {
  const [t] = useTranslation();

  return (
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("panel.customers.checkoutTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("panel.customers.checkoutHint").replace("__guest__", name).replace("__location__", location)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={() => handleClose(true)} color="primary" autoFocus>
            {t("common.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default ConfirmDialog;