import React from "react";
import Grid from "@material-ui/core/Grid";
import {Container, TextField} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import TranslatedTextField from "../../inputs/TranslatedTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.5)
    },
  },
}));

const LocationInfoForm = ({locationDetails, onChange}) => {
  const classes = useStyles();

  return (
      <Container>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <TranslatedTextField
                name="name"
                namespace="location"
                value={locationDetails.name}
                onChange={onChange}
                required
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="address"
                namespace="location"
                value={locationDetails.address}
                onChange={onChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="city"
                namespace="location"
                value={locationDetails.city}
                onChange={onChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="municipality"
                namespace="location"
                value={locationDetails.municipality}
                onChange={onChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="plz"
                namespace="location"
                value={locationDetails.plz}
                onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TranslatedTextField
                name="phoneNumber"
                namespace="location"
                value={locationDetails.phoneNumber}
                onChange={onChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="email"
                namespace="location"
                value={locationDetails.email}
                onChange={onChange}
            />
          </Grid>
          <Grid item sm={6}>
            <TranslatedTextField
                name="website"
                namespace="location"
                value={locationDetails.website}
                onChange={onChange}
            />
          </Grid>
        </Grid>
      </Container>
  )
}

export default LocationInfoForm;