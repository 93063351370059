import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
import {fetchUserLocations} from "../../../api/api";
import {formatAddress} from "../../../utils/utils";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
});

export default function Locations() {
  const classes = useStyles();
  const [t] = useTranslation();
  const [dataset, setDataset] = useState({
    rows: []
  });

  useEffect(() => {
    fetchUserLocations()
        .then(resp => {
          if (!resp.error) {
            const body = resp.response;
            setDataset({
              rows: body,
            })
          }
        });
  }, []);

  return (
      <div>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>{t("panel.locations.name")}</TableCell>
                  <TableCell>{t("panel.locations.address")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataset.rows.map((row) => (
                    <TableRow hover style={{textDecoration: "none"}} key={row.id} component={Link}
                              to={`/locations/${row.id}`}>
                      <TableCell>{row.imageUrl && <img src={row.imageUrl} height="25px" width="25px" border="none"/>}</TableCell>
                      <TableCell><strong>{row.name}</strong></TableCell>
                      <TableCell><strong>{formatAddress(row.address)}</strong></TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br/>
          <Button component={Link} to="/locations/new" variant="contained" color="primary">
            {t("panel.locations.create")}
          </Button>
      </div>
  );
}