import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {Link} from "react-router-dom";
import {fetchCustomers} from "../../../api/api";
import {AuthContext} from "../../../App";
import {useTranslation} from "react-i18next";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 800,
  },
  search: {
    width: '20%',
    margin: "5px 5px"
  }
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [t] = useTranslation();
  const {locations} = useContext(AuthContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataset, setDataset] = useState({
    rows: [],
    count: 0
  });
  const [searchText, setSearchText] = useState("");
  const [guestCodeQuery, setGuestCodeQuery] = useState("");

  useEffect(() => {
    fetchCustomers(page, rowsPerPage, guestCodeQuery)
        .then(resp => {
          if (!resp.error) {
            const body = resp.response;
            setDataset({
              rows: body.content,
              count: body.totalElements
            })
          }
        });
  }, [page, rowsPerPage, guestCodeQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const locationName = id => {
    return locations.find(l => l.id === id).name;
  }

  return (
      <Paper className={classes.root}>
        <SearchBar
            className={classes.search}
            value={searchText}
            onChange={newValue => setSearchText(newValue)}
            onRequestSearch={() => setGuestCodeQuery(searchText)}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>{t("panel.customers.id")}</TableCell>
                <TableCell>{t("panel.customers.firstName")}</TableCell>
                <TableCell>{t("panel.customers.lastName")}</TableCell>
                <TableCell>{t("panel.customers.email")}</TableCell>
                <TableCell>{t("panel.customers.phoneNumber")}</TableCell>
                <TableCell>{t("panel.customers.location")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataset.rows.map((row) => (
                  <TableRow hover style={{textDecoration: "none"}} key={row.id} component={Link}
                            to={`/customers/${row.id}`}>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{locationName(row.signupLocationId)}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {dataset.count > 10 && (<TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={dataset.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />)}
      </Paper>
  );
}