import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import * as Yup from "yup";
import {createAccount, fetchInvitation, signUpGuest} from "../../api/api";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Field, Form, Formik} from "formik";
import FormTextField from "../../components/forms/FormTextField";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  header: {
    spacing: '5'
  }
}));

export default function UserSignup() {
  const [t] = useTranslation();
  const history = useHistory();
  const {token} = useParams();
  const [email, setEmail] = useState('');
  const classes = useStyles();
  useEffect(() => {
    if (token) {
      fetchInvitation(token)
          .then(resp => {
            if (!resp.error) {
              setEmail(resp.response.email);
            }
          });
    }
  }, [token]);

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .required(t("guest.signup.firstName.validation.required")),
    lastName: Yup.string()
        .required(t("guest.signup.lastName.validation.required")),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const showErrors = (errCode, setErrors) => {
    if (errCode.startsWith("ERR_EMAIL")) {
      setErrors({email: t(`guest.signup.email.validation.backend.${errCode}`)})
    }
  }

  const onSubmit = async (values, {setErrors}) => {
    const resp = await createAccount({
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      email: email,
      invitationToken: token
    });
    console.log(resp);
    if (!resp.error) {
      history.push('/')
    }
  }

  return (
      <Container component="main" maxWidth="md">
        <Grid container xs={10}>
          <CssBaseline/>
          <div className={classes.paper}>
            <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  password: ''
                }}
                validationSchema={SignupSchema}
                onSubmit={onSubmit}
            >
              {({errors, touched}) => (
                  <Form className={classes.form} noValidate>
                    <TextField
                        fullWidth
                        id='email'
                        label='E-mail'
                        name='email'
                        variant="outlined"
                        autoComplete='email'
                        margin="normal"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        disabled
                    />
                    <FormTextField
                        name="firstName"
                        namespace="guest.signup"
                        required
                    />
                    <FormTextField
                        name="lastName"
                        namespace="guest.signup"
                        required
                    />
                    <FormTextField
                        name="password"
                        namespace="guest.signup"
                        required
                        label="Password"
                        type="password"
                    />
                    <FormTextField
                        name="passwordConfirmation"
                        namespace="guest.signup"
                        required
                        label="Password confirmation"
                        type="password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                      {t("guest.signup.submit")}
                    </Button>
                  </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Container>

  );
}