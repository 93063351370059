import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useParams} from "react-router-dom";
import {fetchLocationDetails} from "../../../api/api";
import {Divider, Grid, Typography} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {formatAddress, formatWorkHours} from "../../../utils/utils";
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const LocationDetails = props => {
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const [location, setLocation] = useState(null);
  const {id} = useParams('id');
  useEffect(() => {
    fetchLocationDetails(id)
        .then(response => {
          if (!response.error) {
            setLocation(response.response);
          }
        });
  }, []);

  if (!location) {
    return <div>LOADING</div>
  }

  const handleEditClick = () => {
    history.push(`/locations/${id}/edit`)
  }

  return (
      <>
        <Typography variant="h2">
          {`${location.name}`}
        </Typography>
        {location && <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>{t("panel.locations.address")}</TableCell>
                <TableCell>{formatAddress(location.address)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("location.phoneNumber.label")}</TableCell>
                <TableCell>{location.contactInfo.phoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("location.email.label")}</TableCell>
                <TableCell>{location.contactInfo.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("location.website.label")}</TableCell>
                <TableCell>{location.contactInfo.website}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.monday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.monday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.tuesday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.tuesday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.wednesday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.wednesday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.thursday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.thursday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.friday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.friday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.saturday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.saturday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("common.sunday")}</TableCell>
                <TableCell>{`${formatWorkHours(location.workSchedule.sunday)}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("panel.locations.landingPage")}</TableCell>
                <TableCell>
                  <a href={`/signup/${location.uriSafeName}`} target="_blank">
                    <span>{location.uriSafeName} <LaunchIcon fontSize="small"/>
                    </span>
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>}
        <br/>
        <Button
            variant="contained"
            color="primary"
            onClick={handleEditClick}
        >
          <span>{t("common.edit")} <EditIcon fontSize="small"/> </span>
        </Button>
        <br />
        <br />
        <Button
            variant="contained"
            color="default"
            component="a" href={`/qr/locations/${location.id}`} target="_blank">
          <span>QR CODE <LaunchIcon
              fontSize="small"/> </span>
        </Button>
      </>
  );
};

export default LocationDetails;